

import storeCode from '~/mixins/storeCode'

export default {
  name: 'HomePageImagesBanners',
  mixins: [
    storeCode,
  ],

  props: {
    api: {
      type: Object,
      default: null,
    },
  },

  computed: {
    shouldShowTitle () {
      return !this.isMedistoreStore
    },

    titleTag () {
      return this.isMedicoverAptekaStore ? 'h1' : 'h2'
    },

    titleText () {
      return this.isMedicoverAptekaStore
        ? this.$t('Medicover Vital – Dietary Supplements for Health and Beauty')
        : this.$t('Latest offers')
    },
  },

  methods: {
    onBannerClick (item) {
      // gtm
    },

    isUrlRelative (url) {
      return url && url[0] === '/'
    },
  },
}
